import { Contract } from '@ethersproject/contracts'
import { parseUnits } from '@ethersproject/units'
import BigNumber from 'bignumber.js'
import transferGateAbi from '../constants/abis/transferGate.json'
import { Web3Provider } from '@ethersproject/providers'
import { BASE_ADDRESS, TRANSFER_GATE_ADDRESS, POOL_ADDRESS } from '../constants'
import { getDisplayBalance } from '../utils/formatBalance'

export class TransferGateService {
    private contract: Contract;
    private account: string;

    constructor(library: Web3Provider, account: string) {
        this.account = account;
        const signer = library.getSigner(account).connectUnchecked()
        this.contract = new Contract(TRANSFER_GATE_ADDRESS, transferGateAbi, signer);
    }

    public async getFees(inputAddress: string) {      
        if (inputAddress === BASE_ADDRESS) {
            const rate = await this.contract.feesRate();
            return parseFloat((rate).toString())/100;
        }
        const poolTax = await this.contract.poolsTaxRates(POOL_ADDRESS);
        const dumpTax = await this.contract.getDumpTax();
        const totalTax = parseFloat((poolTax).toString()) + parseFloat((dumpTax).toString());
        return totalTax/100;
    }

    public async getInputAmount(output: string, inputAddress: string, outputAddress: string, intputDecimals: number, outputDecimals: number) {
        const outputs: any = await this.contract.getAmountsIn(parseUnits(output, outputDecimals), [inputAddress, outputAddress]);
        return getDisplayBalance(new BigNumber(outputs[0].toString()), 6, intputDecimals);
    }

    public async swap(input: string, minOutputAmount: string, inputAddress: string, intputDecimals: number, outputDecimals: number) {
        if (inputAddress === BASE_ADDRESS) {
            return await this.contract.buyRooted(parseUnits(input, intputDecimals), parseUnits(minOutputAmount, outputDecimals), this.account);
        }
        return await this.contract.sellRooted(parseUnits(input, intputDecimals), parseUnits(minOutputAmount, outputDecimals), this.account);
    }
}