import React, { useEffect, useState } from 'react'
import { Box, Button, Heading, Image, Text } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { StakingService } from '../../services/StakingService';
import { shortenAddress, supportedChain } from '../../utils';
import { TokenService } from '../../services/TokenService';
import { GlobalConst } from '../../constants';
import { extractErrorMessage } from '../../utils/extractErrorMessage';
import { PayFeesService } from '../../services/PayFeesService';
import useTokenBalance from '../../hooks/useTokenBalance';
// import TransactionCompletedModal from '../TransactionCompletedModal';
import { getBalanceNumber, getDisplayBalance, getFullDisplayBalance } from '../../utils/formatBalance';
import { PendingContent } from '../Button';
import CurrencyInput2 from '../CurrencyInput2';
import { ErrorMessage } from '../ErrorMessage';
import useCopyClipboard from 'utils/useCopyClipboard';

enum Action {
  Stake,
  Unstake
}

enum StakingStatus {
  None,
  Approving,
  Approved,
  Staking,
  Staked
}

enum PayFeesStatus {
  None,
  Pending,
  Success
}

export default function StakeTabOne() {
  const { account, library, chainId } = useWeb3React();
  const [action, setAction] = useState<Action>(Action.Stake);
  const rootedBalance = useTokenBalance(GlobalConst.addresses.ROOTED_ADDRESS);
  const stakingBalance = useTokenBalance(GlobalConst.addresses.STAKING_ADDRESS);
  const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
  const [value, setValue] = useState<string>("");
  const [status, setStatus] = useState<StakingStatus>(StakingStatus.None);
  const [payFeesStatus, setPayFeesStatus] = useState<PayFeesStatus>(PayFeesStatus.None);
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [error, setError] = useState("");
  const [completedAction, setCompletedAction] = useState("");
  const [pendingAction, setPendingAction] = useState("");
  const [rate, setRate] = useState("");
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isCopied, setCopied] = useCopyClipboard()

  useEffect(() => {
    setBalance(action === Action.Stake ? rootedBalance : stakingBalance)
  }, [action, rootedBalance, stakingBalance])


  useEffect(() => {
    const getRate = async () => {
      const stakedPerRooted = await new StakingService(library, account!).getRate();
      setRate(`${action === Action.Stake ? `1 ${GlobalConst.utils.ROOTED_TICKER} = ${stakedPerRooted.toFixed(4)} ${GlobalConst.utils.STAKING_TICKER}` : `1 ${GlobalConst.utils.STAKING_TICKER} = ${(1 / stakedPerRooted).toFixed(4)} ${GlobalConst.utils.ROOTED_TICKER}`}`);
    }

    if (account && supportedChain(chainId)) {
      getRate();
    }
    const timer = setInterval(() => getRate(), 30000)
    return () => clearInterval(timer)
  }, [library, account, chainId, action])

  useEffect(() => {
    const getIsApprove = async () => {
      const service = new TokenService(library, account!, GlobalConst.addresses.ROOTED_ADDRESS);
      const approved = await service.isApproved(GlobalConst.addresses.STAKING_ADDRESS);
      setIsApproved(approved);
      if (approved) {
        setStatus(StakingStatus.Approved);
      }
    }
    if (account && supportedChain(chainId)) {
      getIsApprove();
    }
  }, [library, account, chainId])

  const approve = async () => {
    try {
      setStatus(StakingStatus.Approving);
      const service = new TokenService(library, account!, GlobalConst.addresses.ROOTED_ADDRESS);
      const txResponse = await service.approve(GlobalConst.addresses.STAKING_ADDRESS);
      if (txResponse) {
        const receipt = await txResponse.wait()
        if (receipt?.status === 1) {
          setTransactionHash(receipt.transactionHash);
        }
        else {
          setError("Transaction Failed")
        }
      }
      setStatus(StakingStatus.Approved);
      setIsApproved(true);
    }
    catch (e) {
      console.log(e);
      const errorMessage = extractErrorMessage(e);
      if (errorMessage) {
        setError(errorMessage);
      }
      setStatus(StakingStatus.None);
    }
  }

  const stake = async () => {
    const amount = parseFloat(value);
    if (Number.isNaN(amount) || amount <= 0) {
      setError("Enter amount");
      return;
    }
    setError("");

    try {
      setCompletedAction(`${value} ${action === Action.Stake ? GlobalConst.utils.ROOTED_TICKER : GlobalConst.utils.STAKING_TICKER} ${action === Action.Stake ? "staked" : "unstaked"}`);
      setPendingAction(`${action === Action.Stake ? "staking" : "unstaking"}...`);
      setStatus(StakingStatus.Staking);

      const service = new StakingService(library, account!)
      const txResponse = action === Action.Stake
        ? await service.stake(value)
        : await service.unstake(value)

      if (txResponse) {
        const receipt = await txResponse.wait()
        if (receipt?.status === 1) {
          setTransactionHash(receipt.transactionHash);
        }
        else {
          setError("Transaction Failed")
        }
      }
      setStatus(StakingStatus.Staked);
      setValue("");
    }
    catch (e) {
      console.log(e)
      const errorMessage = extractErrorMessage(e);
      if (errorMessage) {
        setError(errorMessage);
      }
      setStatus(StakingStatus.None)
    }
  }

  const payFees = async () => {
    setError("");
    setPayFeesStatus(PayFeesStatus.Pending);

    try {
      const service = new PayFeesService(library, account!)
      await service.payFees();
      setPayFeesStatus(PayFeesStatus.Success);
    } catch (e) {
      setPayFeesStatus(PayFeesStatus.None)
    }
  }
  return (
    <>
      <Box className='stakone_main_border'>
        <Box className='stakone_main'>
          <Heading as="h4" >Staking</Heading>
          {/* <Box className='stake_unstake_cro_btn_prnt'>
                  <Button>Staking</Button>
                  <Button>Vault Staking</Button>
              </Box> */}
          <Tabs variant='unstyled'>
            <TabList className='tab_btn_prnt'>
              <Box className='tab_border'>
                <Tab bg={'#00FF73'} _selected={{ color: '#FFFFFF', bg: 'transparent' }} className="staktab01" onClick={() => setAction(Action.Stake)}>Stake {GlobalConst.utils.ROOTED_TICKER}</Tab>
                <Tab bg={'#00FF73'} _selected={{ color: '#FFFFFF', bg: 'transparent' }} className="staktab02" onClick={() => setAction(Action.Unstake)}>Unstake {GlobalConst.utils.STAKING_TICKER}</Tab>
              </Box>
            </TabList>
            <TabPanels className='tab_pannel_padd'>
              <TabPanel className='stake_tab_panel01_prnt'>
                <Box className='stake_tab_panel01'>
                  {/* <Heading as="h6">1 upTether = 0.9851 xUpTether</Heading> */}
                  <Heading as="h6">{rate}</Heading>
                  <CurrencyInput2
                    value={value}
                    balance={getDisplayBalance(balance, 2)}
                    numericBalance={getBalanceNumber(balance)}
                    onSubmit={stake}
                    ticker={action === Action.Stake ? GlobalConst.utils.ROOTED_TICKER : GlobalConst.utils.STAKING_TICKER}
                    label={`Amount to ${action === Action.Stake ? "stake" : "unstake"}`}
                    onMax={() => setValue(getFullDisplayBalance(balance))}
                    showMaxButton={true}
                    onUserInput={setValue}
                    id={"stakingInput"}
                  />
                  {/* <Box className='bord_stak_input'>
                  <Box className='stake_inpt_box'>
                    <Box className='text_row'>
                      <Text>Amount to stake</Text>
                      <Text>Balance: 0.00</Text>
                    </Box>
                    <Box className='inpt_btn_row'>
                      <input type="number" value="0.0"></input>
                      <Box className='btn_text_prnt'>
                        <Button>MAX</Button>
                        <Text>upTether</Text>
                      </Box>
                    </Box>
                  </Box>
                </Box> */}

                  {action === Action.Unstake || isApproved
                    ?
                    <Button className='stake_full_btn' disabled={status === StakingStatus.Staking || !supportedChain(chainId)} onClick={stake}>
                      {status === StakingStatus.Staking
                        ? <PendingContent text={pendingAction} />
                        : `${action === Action.Stake ? "Stake" : "Unstake"}`}
                    </Button>
                    :
                    <div>
                      <Button className='stake_full_btn' onClick={approve} disabled={status === StakingStatus.Approving || !supportedChain(chainId)}>
                        {status === StakingStatus.Approving
                          ? <PendingContent text={"Approving..."} />
                          : status === StakingStatus.Approved ? "Approved" : "Approve"
                        }
                      </Button>
                      <br />
                      <Button className='stake_full_btn' disabled={status !== StakingStatus.Approved || !supportedChain(chainId)} onClick={stake}>
                        {status === StakingStatus.Staking
                          ? <PendingContent text={"Staking..."} />
                          : "Stake"
                        }
                      </Button>
                    </div>
                  }

                  {/* <Button className='stake_full_btn' disabled={payFeesStatus !== PayFeesStatus.None || !supportedChain(chainId)} onClick={payFees}>
                    {payFeesStatus === PayFeesStatus.Pending
                      ? <PendingContent text={"Pay Fees..."} />
                      : payFeesStatus === PayFeesStatus.None ? "Pay Fees" :
                        "Pay Fees Success"
                    }
                  </Button> */}
                </Box>
                <Box className='stake_emp_dex_btns'>
                  {/* <Button disabled >Stake</Button> */}
                  <a href='https://quickswap.exchange/#/analytics/token/0xbfdf833e65bd8b27c84fbe55dd17f7648c532168'><Button>QuickSwap</Button></a>
                  <a href='https://polygon.poocoin.app/tokens/0xbfdf833e65bd8b27c84fbe55dd17f7648c532168'><Button>Poocoin</Button></a>
                </Box>
              </  TabPanel>
              <TabPanel className='stake_tab_panel01_prnt stake_tab_panel02_prnt'>
                <Box className='stake_tab_panel01'>
                  {/* <Heading as="h6">1 upTether = 1.0151 xUpTether</Heading> */}
                  <Heading as="h6">{rate}</Heading>
                  <CurrencyInput2
                    value={value}
                    balance={getDisplayBalance(balance, 2)}
                    numericBalance={getBalanceNumber(balance)}
                    onSubmit={stake}
                    ticker={action === Action.Stake ? GlobalConst.utils.ROOTED_TICKER : GlobalConst.utils.STAKING_TICKER}
                    label={`Amount to ${action === Action.Stake ? "stake" : "unstake"}`}
                    onMax={() => setValue(getFullDisplayBalance(balance))}
                    showMaxButton={true}
                    onUserInput={setValue}
                    id={"stakingInput"}
                  />
                  {/* <Box className='stake_inpt_box'>
                  <Box className='text_row'>
                    <Text>Amount to unstake</Text>
                    <Text>Balance: 0.00</Text>
                  </Box>
                  <Box className='inpt_btn_row'>
                    <input type="number" value="0.0"></input>
                    <Box className='btn_text_prnt'>
                      <Button className='max_btn_swap'><span>MAX</span></Button>
                      <Text>xUpTether</Text>
                    </Box>
                  </Box>
                </Box> */}
                  {action === Action.Unstake || isApproved
                    ?
                    <Button className='stake_full_btn' disabled={status === StakingStatus.Staking || !supportedChain(chainId)} onClick={stake}>
                      {status === StakingStatus.Staking
                        ? <PendingContent text={pendingAction} />
                        : `${action === Action.Stake ? "Stake" : "Unstake"}`}
                    </Button>
                    :
                    <div>
                      <Button className='stake_full_btn' onClick={approve} disabled={status === StakingStatus.Approving || !supportedChain(chainId)}>
                        {status === StakingStatus.Approving
                          ? <PendingContent text={"Approving..."} />
                          : status === StakingStatus.Approved ? "Approved" : "Approve"
                        }
                      </Button>
                      <br />
                      <Button className='stake_full_btn' disabled={status !== StakingStatus.Approved || !supportedChain(chainId)} onClick={stake}>
                        {status === StakingStatus.Staking
                          ? <PendingContent text={"Staking..."} />
                          : "Stake"
                        }
                      </Button>
                    </div>
                  }

                  {/* <Button className='stake_full_btn' disabled={payFeesStatus !== PayFeesStatus.None || !supportedChain(chainId)} onClick={payFees}>
                    {payFeesStatus === PayFeesStatus.Pending
                      ? <PendingContent text={"Pay Fees..."} />
                      : payFeesStatus === PayFeesStatus.None ? "Pay Fees" :
                        "Pay Fees Success"
                    }
                  </Button> */}
                </Box>

                {error ? <ErrorMessage error={error} /> : null}
                <Box className='stake_emp_dex_btns stake_emp_dex_btns02'>
                  <a href='https://quickswap.exchange/#/analytics/token/0xbfdf833e65bd8b27c84fbe55dd17f7648c532168'><Button>QuickSwap</Button></a>
                  <a href='https://polygon.poocoin.app/tokens/0xbfdf833e65bd8b27c84fbe55dd17f7648c532168'><Button>Poocoin</Button></a>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <Box className='contracts_box'>
        <Heading as="h4">Contracts</Heading>
        <Box className='upcro_copyflex'>
          <Heading as="h6">
            {GlobalConst.utils.ROOTED_TICKER}
            <Text>
              {shortenAddress(GlobalConst.addresses.ROOTED_ADDRESS)}
              <Button onClick={() => setCopied(GlobalConst.addresses.ROOTED_ADDRESS)}>
                <Image src="img/copy_ic.svg" alt='' />
              </Button>
            </Text>
          </Heading>
          <Heading as="h6" className='right_h6'>
            {GlobalConst.utils.STAKING_TICKER}
            <Text>
              {shortenAddress(GlobalConst.addresses.STAKING_ADDRESS)}
              <Button onClick={() => setCopied(GlobalConst.addresses.STAKING_ADDRESS)}>
                <Image src="img/copy_ic.svg" alt='' />
              </Button>
            </Text>
          </Heading>
        </Box>
        <Box className='upcro_copyflex'></Box>
      </Box>
    </>
  )
}
