import React, { useCallback, useState } from 'react';
import { Currency } from '@uniswap/sdk';
import { Box } from '@material-ui/core';
import { useCurrencyBalance } from 'state/wallet/hooks';
import { CurrencySearchModal, CurrencyLogo, NumericalInput } from 'components';
import { useActiveWeb3React } from 'hooks';
import useUSDCPrice from 'utils/useUSDCPrice';
import { formatTokenAmount } from 'utils';
import 'components/styles/CurrencyInput.scss';
import { useTranslation } from 'react-i18next';
import { Button, Image, Text } from '@chakra-ui/react';
import { GlobalConst } from 'constants/index';

interface CurrencyInputProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  hideBalance?: boolean
  hideInput?: boolean
  disabled?: boolean
  balance?: string,
  numericBalance?: number,
  balanceLabel?: string
  ticker: string
  id: string
}

const CurrencyInputOld: React.FC<CurrencyInputProps> = ({
  value,
  onUserInput,
  onMax,
  label = 'Input',
  showMaxButton,
  hideBalance = false,
  hideInput = false,
  disabled = false,
  balance,
  numericBalance,
  balanceLabel = 'Balance',
  ticker,
  id
}) => {

  return (
    <>
      <Box className='swap_upcro_brdr_bx'>
        <Box className='flex_bx'>
          {/* <input type="number" value="281.594" /> */}
          <NumericalInput
            value={value}
            placeholder='0.00'
            onUserInput={(val) => {
              onUserInput(val);
            }}
          />
          <Button
            className='upmatic_btn_pop'
          >
            {ticker}
            <Image
              src='/img/pop_01.svg'
              alt=''
              className='down_arrow'
            />
          </Button>
        </Box>

        <Box className='flex_bx flex_bx_02'>
          <Text></Text>
          <Text onClick={onMax} style={{cursor:"pointer"}}>Balance: {balance}</Text>
          {/* <Text>${(usdPrice * Number(amount)).toLocaleString()}</Text> */}
          {/* <Text>Balance: {formatTokenAmount(selectedCurrencyBalance)}</Text> */}
        </Box>        
      </Box>
    </>
  );
};

export default CurrencyInputOld;
