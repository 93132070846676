import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Currency, CurrencyAmount, JSBI, Token, Trade } from '@uniswap/sdk';
import ReactGA from 'react-ga';
import { ArrowDown } from 'react-feather';
// import { Button } from '@material-ui/core';
import { Box, CircularProgress, Button, Image, Text } from '@chakra-ui/react';
import { useWalletModalToggle } from 'state/application/hooks';
import {
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from 'state/swap/hooks';
import {
  useExpertModeManager,
  useUserSlippageTolerance,
} from 'state/user/hooks';
import { Field } from 'state/swap/actions';
import { useAllTokens } from 'hooks/Tokens';
import {
  CurrencyInputOld,
  AdvancedSwapDetails,
  AddressInput,
  ConfirmSwapModal,
} from 'components';
import { useActiveWeb3React } from 'hooks';
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from 'hooks/useApproveCallback';
import { useSwapCallback } from 'hooks/useSwapCallback';
import { useTransactionFinalizer } from 'state/transactions/hooks';
import useENSAddress from 'hooks/useENSAddress';
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback';
import useToggledVersion, { Version } from 'hooks/useToggledVersion';
import {
  addMaticToMetamask,
  isSupportedNetwork,
  confirmPriceImpactWithoutFee,
  maxAmountSpend,
} from 'utils';
import { computeTradePriceBreakdown, warningSeverity } from 'utils/prices';
import { ReactComponent as PriceExchangeIcon } from 'assets/images/PriceExchangeIcon.svg';
import { ReactComponent as ExchangeIcon } from 'assets/images/ExchangeIcon.svg';
import 'components/styles/Swap.scss';
import { useTranslation } from 'react-i18next';
import useUSDCPrice from 'utils/useUSDCPrice';
import { BASE_ADDRESS, BASE_DECIMALS, BASE_TICKER, ROOTED_ADDRESS, ROOTED_DECIMALS, ROOTED_TICKER, ROUTER_ADDRESS } from '../../constants';
import BigNumber from 'bignumber.js'
import { getDisplayBalance, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { RouterService } from 'services/RouterService';
import { TransferGateService } from 'services/TransferGateService';
import useTokenBalance from 'hooks/useTokenBalance';
import { TokenService } from 'services/TokenService';
import { PendingContent } from 'components/Button';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { ErrorMessage } from 'components/ErrorMessage';
// import SwapPrice from './SwapPrice';

enum SwappingStatus {
  None,
  Approving,
  Approved,
  Swapping,
  Swapped
}

const Swap: React.FC<{
  currency0?: Currency;
  currency1?: Currency;
  currencyBgClass?: string;
}> = ({ currency0, currency1, currencyBgClass }) => {
  const [status, setStatus] = useState<SwappingStatus>(SwappingStatus.None);
  const [inputValue, setInputValue] = useState<string>("");    
  const [inputBalance, setInputBalance] = useState<BigNumber>(new BigNumber(0));
  const [inputDecimals, setInputDecimals] = useState<number>(BASE_DECIMALS);
  const [inputTicker, setInputTicker] = useState<string>(BASE_TICKER);
  const [inputAddress, setInputAddress] = useState<string>(BASE_ADDRESS);
  const [outputValue, setOutputValue] = useState<string>("");
  const [outputDecimals, setOutputDecimals] = useState<number>(ROOTED_DECIMALS);
  const [outputAddress, setOutputAddress] = useState<string>(ROOTED_ADDRESS);
  const [outputBalance, setOutputBalance] = useState<BigNumber>(new BigNumber(0));
  const [outputTicker, setOutputTicker] = useState<string>(ROOTED_TICKER);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isBaseApproved, setIsBaseApproved] = useState<boolean>(false);
  const [isRootedApproved, setIsRootedApproved] = useState<boolean>(false);
  const baseBalance = useTokenBalance(BASE_ADDRESS);
  const rootedBalance = useTokenBalance(ROOTED_ADDRESS);
  const [fees, setFees] = useState<string>("0");
  const [slippage, setSlippage] = useState<string>("0");
  const [error, setError] = useState<string>("");
  const [transactionHash, setTransactionHash] = useState<string>("");

  const { t } = useTranslation();
  const { account, library, chainId } = useActiveWeb3React();
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const toggledVersion = useToggledVersion();
  const finalizedTransaction = useTransactionFinalizer();
  const [isExpertMode] = useExpertModeManager();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue,
  );
  const allTokens = useAllTokens();

  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade,
  };
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion];
  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const { address: recipientAddress } = useENSAddress(recipient);
  const [allowedSlippage] = useUserSlippageTolerance();
  const [approving, setApproving] = useState(false);
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage,
  );
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;
  const parsedAmounts = useMemo(() => {
    return showWrap
      ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
      : {
        [Field.INPUT]:
          independentField === Field.INPUT
            ? parsedAmount
            : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };
  }, [parsedAmount, independentField, trade, showWrap]);
  const formattedAmounts = useMemo(() => {
    return {
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : parsedAmounts[dependentField]?.toExact() ?? '',
    };
  }, [independentField, typedValue, dependentField, showWrap, parsedAmounts]);
  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
    currencies[Field.OUTPUT] &&
    parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
  );
  const noRoute = !route;

  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade);
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);
  const { ethereum } = window as any;
  const [mainPrice, setMainPrice] = useState(true);
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);
  const isValid = !swapInputError;

  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const toggleWalletModal = useWalletModalToggle();

  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const connectWallet = () => {
    if (ethereum && !isSupportedNetwork(ethereum)) {
      addMaticToMetamask();
    } else {
      toggleWalletModal();
    }
  };

  const handleCurrencySelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
    },
    [onCurrencySelection],
  );

  const handleOtherCurrencySelect = useCallback(
    (outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection],
  );

  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
  );

  const swapButtonText = useMemo(() => {
    if (account) {
      if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
        return t('Select Token');
      } else if (
        formattedAmounts[Field.INPUT] === '' &&
        formattedAmounts[Field.OUTPUT] === ''
      ) {
        return t('Enter Amount');
      } else if (showWrap) {
        return wrapType === WrapType.WRAP
          ? t('Wrap')
          : wrapType === WrapType.UNWRAP
            ? t('Unwrap')
            : '';
      } else if (noRoute && userHasSpecifiedInputOutput) {
        return t('Insufficient Liquidity Trade');
      } else {
        return swapInputError ?? t('Swap');
      }
    } else {
      return ethereum && !isSupportedNetwork(ethereum)
        ? t('Switch to Polygon')
        : t('Connect Wallet');
    }
  }, [
    t,
    formattedAmounts,
    currencies,
    account,
    ethereum,
    noRoute,
    userHasSpecifiedInputOutput,
    showWrap,
    wrapType,
    swapInputError,
  ]);

  const swapButtonDisabled = useMemo(() => {
    if (account) {
      if (showWrap) {
        return Boolean(wrapInputError);
      } else if (noRoute && userHasSpecifiedInputOutput) {
        return true;
      } else if (showApproveFlow) {
        return (
          !isValid ||
          approval !== ApprovalState.APPROVED ||
          (priceImpactSeverity > 3 && !isExpertMode)
        );
      } else {
        return (
          !isValid ||
          (priceImpactSeverity > 3 && !isExpertMode) ||
          !!swapCallbackError
        );
      }
    } else {
      return false;
    }
  }, [
    account,
    showWrap,
    wrapInputError,
    noRoute,
    userHasSpecifiedInputOutput,
    showApproveFlow,
    approval,
    priceImpactSeverity,
    isValid,
    swapCallbackError,
    isExpertMode,
  ]);

  const [
    {
      showConfirm,
      txPending,
      tradeToConfirm,
      swapErrorMessage,
      attemptingTxn,
      txHash,
    },
    setSwapState,
  ] = useState<{
    showConfirm: boolean;
    txPending?: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    txPending: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput],
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput],
  );

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT],
  );

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact());
  }, [maxAmountInput, onUserInput]);

  const handleHalfInput = useCallback(() => {
    maxAmountInput &&
      onUserInput(
        Field.INPUT,
        (Number(maxAmountInput.toExact()) / 2).toString(),
      );
  }, [maxAmountInput, onUserInput]);

  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput),
  );

  const onSwap = () => {
    if (showWrap && onWrap) {
      onWrap();
    } else if (isExpertMode) {
      handleSwap();
    } else {
      setSwapState({
        tradeToConfirm: trade,
        attemptingTxn: false,
        swapErrorMessage: undefined,
        showConfirm: true,
        txHash: undefined,
      });
    }
  };

  useEffect(() => {
    onCurrencySelection(Field.INPUT, Token.ETHER);
  }, [onCurrencySelection, allTokens]);

  useEffect(() => {
    if (currency0) {
      onCurrencySelection(Field.INPUT, currency0);
    }
    if (currency1) {
      onCurrencySelection(Field.OUTPUT, currency1);
    }
  }, [onCurrencySelection, currency0, currency1]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn,
      showConfirm,
    });
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash]);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({
      showConfirm: false,
      tradeToConfirm,
      attemptingTxn,
      swapErrorMessage,
      txHash,
    });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '');
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
    });
    swapCallback()
      .then(async ({ response, summary }) => {
        setSwapState({
          attemptingTxn: false,
          txPending: true,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: response.hash,
        });

        try {
          const receipt = await response.wait();
          finalizedTransaction(receipt, {
            summary,
          });
          setSwapState({
            attemptingTxn: false,
            txPending: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: undefined,
            txHash: response.hash,
          });
          ReactGA.event({
            category: 'Swap',
            action:
              recipient === null
                ? 'Swap w/o Send'
                : (recipientAddress ?? recipient) === account
                  ? 'Swap w/o Send + recipient'
                  : 'Swap w/ Send',
            label: [
              trade?.inputAmount?.currency?.symbol,
              trade?.outputAmount?.currency?.symbol,
            ].join('/'),
          });
        } catch (error) {
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: (error as any).message,
            txHash: undefined,
          });
        }
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        });
      });
  }, [
    tradeToConfirm,
    account,
    priceImpactWithoutFee,
    recipient,
    recipientAddress,
    showConfirm,
    swapCallback,
    finalizedTransaction,
    trade,
  ]);


  useEffect(() => {
    const getIsApprove = async () => {
        let service = new TokenService(library!, account!, ROOTED_ADDRESS);
        let approved = await service.isApproved(ROUTER_ADDRESS);
        setIsRootedApproved(approved);
        if(approved && inputAddress === ROOTED_ADDRESS) {
            setStatus(SwappingStatus.Approved);               
        }

        service = new TokenService(library!, account!, BASE_ADDRESS);
        approved = await service.isApproved(ROUTER_ADDRESS);
        setIsBaseApproved(approved);
        if (approved && inputAddress === BASE_ADDRESS) {
            setStatus(SwappingStatus.Approved);               
        }
    }
    if(account) {
        getIsApprove();
    }
  }, [library, account, chainId])


  useEffect(() => {
      const getSlippage = async () => {
          const service = new TransferGateService(library!, account!);
          const transferFees = await service.getFees(BASE_ADDRESS);
          setFees(transferFees.toString());
          setSlippage((transferFees + 2).toString());          
      }
      if(account) {
          getSlippage();
      }
  }, [library, account, chainId, BASE_ADDRESS])


  useEffect(() => {
      if (inputTicker === BASE_TICKER) {          
          setInputBalance(baseBalance);
          setOutputBalance(rootedBalance);
      }
      else {
          setInputBalance(rootedBalance);
          setOutputBalance(baseBalance);
      }
  }, [baseBalance, rootedBalance])

  useEffect(() => {
      if (inputTicker === BASE_TICKER) {          
          setIsApproved(isBaseApproved);
      }
      else {
          setIsApproved(isRootedApproved);
      }
  }, [isBaseApproved, isRootedApproved])

  const approve = async () => {
    try {
        setStatus(SwappingStatus.Approving);
        const isBase = inputAddress === BASE_ADDRESS;
        const service = new TokenService(library!, account!, inputAddress);
        const txResponse = await service.approve(ROUTER_ADDRESS);
        if (txResponse) {
            const receipt = await txResponse.wait()
            if (receipt?.status === 1) {
                setTransactionHash(receipt.transactionHash);
                setStatus(SwappingStatus.Approved);
                if (isBase){
                    setIsBaseApproved(true);
                }
                else {
                    setIsRootedApproved(true);
                }                 
            }
            else {
                setError("Transaction Failed");
                setStatus(SwappingStatus.None);
            }
        }           
    }
    catch (e) {
        console.log(e);
        const errorMessage = extractErrorMessage(e);
        if(errorMessage) {
            setError(errorMessage);
        }
        setStatus(SwappingStatus.None);
    }
}

const swap = async () => {
    const inputAmount = parseFloat(inputValue);
    if (Number.isNaN(inputAmount) || inputAmount <= 0) {
        setError("Enter amount");
        return;
    }       
    
    setError("");

    const outputAmount = parseFloat(outputValue);
    const slippageAmount = parseFloat(slippage);        
    const minOutputAmount = (1 - slippageAmount/100)*outputAmount;
   
    try {
        setStatus(SwappingStatus.Swapping);

        const service = new RouterService(library!, account!)
        const txResponse =  await service.swap(inputAmount.toFixed(inputDecimals), minOutputAmount.toFixed(outputDecimals), inputAddress, inputDecimals, outputDecimals);

        if (txResponse) {
            const receipt = await txResponse.wait()
            if (receipt?.status === 1) {
                setTransactionHash(receipt.transactionHash); 
                setStatus(SwappingStatus.Swapped);
                setInputValue("");
                setOutputValue("");  
            }
            else {
                setError("Transaction Failed");
                setStatus(SwappingStatus.Approved);
            }
        }         
    }
    catch (e) {
        console.log(e)
        const errorMessage = extractErrorMessage(e);
        if(errorMessage) {
            setError(errorMessage);
        }
        setStatus(SwappingStatus.Approved)
    }
}

  const onInputValueChanged = async (value: string) => {
    setInputValue(value);
    const amount = parseFloat(value);

    if (value === "" || amount === 0){
        setOutputValue("");
        return;
    }
    if (!Number.isNaN(amount) && library && account) {
        const routerService = new RouterService(library, account!);
        setOutputValue(await routerService.getOutputAmount(amount.toFixed(inputDecimals), inputAddress, outputAddress, inputDecimals, outputDecimals));
    }
  }

  const onOutputValueChanged = async (value: string) => {
    setOutputValue(value);
    const amount = parseFloat(value);
    
    if (value === "" || amount === 0){
        setInputValue("");
        return;
    }
    if (!Number.isNaN(amount) && library && account) {
        const routerService = new RouterService(library, account!);
        setInputValue(await routerService.getInputAmount(amount.toFixed(outputDecimals), inputAddress, outputAddress, inputDecimals, outputDecimals));
    }
  }

  const switchTokens = async () => {
    setError("");
    const inValue = inputValue;
    setInputValue(outputValue);
    setOutputValue(inValue);
    const service = new TransferGateService(library!, account!);

    if (inputTicker === BASE_TICKER) {
        setIsApproved(isRootedApproved);
        setInputTicker(ROOTED_TICKER);
        setOutputTicker(BASE_TICKER);
        setInputBalance(rootedBalance);
        setOutputBalance(baseBalance);
        setInputDecimals(ROOTED_DECIMALS);
        setOutputDecimals(BASE_DECIMALS);
        setInputAddress(ROOTED_ADDRESS);
        setOutputAddress(BASE_ADDRESS);
        const transferFees = await service.getFees(ROOTED_ADDRESS);
        setFees(transferFees.toString());
        setSlippage((transferFees + 2).toString());
    }
    else {
        setIsApproved(isBaseApproved);
        setInputTicker(BASE_TICKER);
        setOutputTicker(ROOTED_TICKER);
        setInputBalance(baseBalance);
        setOutputBalance(rootedBalance);
        setInputDecimals(BASE_DECIMALS);
        setOutputDecimals(ROOTED_DECIMALS);
        setInputAddress(BASE_ADDRESS);
        setOutputAddress(ROOTED_ADDRESS);
        const transferFees = await service.getFees(BASE_ADDRESS);
        setFees(transferFees.toString());
        setSlippage((transferFees + 2).toString());
    }        
}
  return (
    <Box>

      <CurrencyInputOld
          value={inputValue}
          balance={getDisplayBalance(inputBalance, 2, inputDecimals)}
          numericBalance={getBalanceNumber(inputBalance, inputDecimals)}
          ticker={inputTicker}
          label={"From"}
          onMax={() => onInputValueChanged(getFullDisplayBalance(inputBalance, inputDecimals))}
          showMaxButton={true}
          onUserInput={onInputValueChanged}
          id={"FromInput"}
      />
      <Box className='exchangeSwap'>
        <ExchangeIcon onClick={switchTokens} />
      </Box>

      <CurrencyInputOld
        value={outputValue}
        balance={getDisplayBalance(outputBalance, 2, outputDecimals)}
        numericBalance={getBalanceNumber(outputBalance, outputDecimals)}
        ticker={outputTicker}
        label={"To"}
        onMax={() => onOutputValueChanged(getFullDisplayBalance(outputBalance, outputDecimals))}
        showMaxButton={true}
        onUserInput={onOutputValueChanged}
        id={"ToInput"}
      />
      <Box className='swapButtonWrapper'>
        {!isApproved && (
          <Box width='48%'>
            <Button
              className='stake_full_btn'
              disabled={status === SwappingStatus.Approving}
              onClick={approve}
            >
              {status === SwappingStatus.Approving ? (
                <Box className='content'>
                  {t('Approving')} <CircularProgress size={16} />
                </Box>
              ) : status === SwappingStatus.Approved ? (
                t('Approved')
              ) : (
                `${t('Approve')}`
              )}
            </Button>
          </Box>
        )}
        <Box width={!isApproved ? '48%' : '100%'}>
          <Button
            className='stake_full_btn'
            disabled={!isApproved || status === SwappingStatus.Swapping }
            onClick={account ? swap : connectWallet}
          >
            {status === SwappingStatus.Swapping
                ? <PendingContent text={"Swapping..."}/>
                : "Swap"
            }
          </Button>
        </Box>
      </Box>
      <Box>
      {/* {error ? <ErrorMessage error={error} /> : null}   */}
      </Box>
    </Box>
  );
};

export default Swap;
