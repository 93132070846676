import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Articles from 'pages/Articles';
import Dashboard from 'pages/Dashboard';
import SwapPageOne from 'pages/SwapPageOne';
import SwapPage from 'pages/SwapPage';
import Faq from 'pages/Faq';
import Roadmap from 'pages/Roadmap';
import Pools from 'pages/Pools';
import Staking from 'pages/Staking';

const AppRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path='/'>
          <HomePage />
        </Route>
        <Route exact path='/articles'>
          <Articles />
        </Route>
        <Route exact path='/dashboard'>
          <Dashboard />
        </Route>
        <Route exact path='/staking'>
          <Staking />
        </Route>
        <Route exact path='/swap'>
          <SwapPageOne/>
          {/* <SwapPage /> */}
        </Route>
        <Route exact path='/pools'>
          <Pools />
        </Route>
        <Route exact path='/faq'>
          <Faq />
        </Route>
        <Route exact path='/roadmap'>
          <Roadmap />
        </Route>
      </Switch>
    </>
  );
};

export default AppRoutes;
