import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, Heading, Image, Button, Text } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import {
  getEthPrice,
  getTokenPairs2,
  getBulkPairData,
} from '../../utils';
import Lottie from 'react-lottie'
import useFuse from '../../hooks/useFuse'
import useSortableData from '../../hooks/useSortableData'
import { formatNumber } from '../../functions/format';
import t1 from '../../assets/lottie/TeatherDashboard_2.json'

interface AllPoolProp {
  tokenAddress: string
  isSearchable?: boolean
}

const SortIcon: FC<{ id?: string; direction?: 'ascending' | 'descending'; active: boolean }> = ({
  id,
  active,
  direction,
}) => {
  if (!id || !direction || !active) return <></>
  if (direction === 'ascending') return <Image src="img/up_arrow.svg" alt="" />
  if (direction === 'descending') return <Image src="img/down_arrow.svg" alt="" />
  return <></>

}
export default function AllPool({ tokenAddress, isSearchable = true }: AllPoolProp) {
  const [tokenPairs, updateTokenPairs] = useState<any>(null);

  useEffect(() => {
    async function fetchTokenPairs() {
      const [newPrice] = await getEthPrice();
      const tokenPairs = await getTokenPairs2(tokenAddress);
      const formattedPairs = tokenPairs
        ? tokenPairs.map((pair: any) => {
          return pair.id;
        })
        : [];
      const pairData = await getBulkPairData(formattedPairs, newPrice);
      if (pairData) {
        const pairsFormatted = pairData?.map((pair) => {
          return {
            pair: {
              token0: pair.token0,
              token1: pair.token1,
              id: pair.id,
            },
            liquidity: pair.reserveUSD,
            volume1d: pair.volumeUSD - pair.oneDayVolumeUSD,
            volume1w: pair.volumeUSD - pair.oneWeekVolumeUSD,
          }
        })
        updateTokenPairs(pairsFormatted);
      }
    }
    fetchTokenPairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTokenPairs, tokenAddress]);

  const options = useMemo(
    () => ({
      keys: ['pair.token0.symbol', 'pair.token1.symbol', 'pair.token0.name', 'pair.token1.name'],
      threshold: 0.4,
    }),
    []
  )

  const { result, term, search } = useFuse({
    data: tokenPairs,
    options,
  })

  const { items, requestSort, sortConfig } = useSortableData(result, { key: 'liquidity', direction: 'descending' })

  const top = {
    loop: true,
    autoplay: true,
    animationData: t1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Box className='pools_main pools_main_v2'>
      {/* <Box className='dsbrdanmtnbg'>
        <Lottie options={top} width={1640} height={2057}></Lottie>
      </Box> */}
      <Box className='pool_list_box'>
        <Box className='dashboard_chart_box dashboard_chart_box_last'>
          <Box className='flex_chart_header flex_chart_header_last'>
            <Heading as="h3">List of all pools</Heading>
            {isSearchable ? (
              <Box className='search_bar'>
                <input type="text" placeholder="Type for search" onChange={(e) => search(e.target.value)} value={term} />
                <Button className="serch_btn"><Image src="img/charm_search.svg" alt="" /> </Button>
              </Box>
            ) : null}
          </Box>
          <Box className='pools_tablemain'>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th onClick={() => requestSort('pair.token0.symbol')}>
                    <Box className='pool_head'>
                      Pool
                      <SortIcon
                        id={sortConfig.key}
                        direction={sortConfig.direction}
                        active={sortConfig.key === 'pair.token0.symbol'} />
                    </Box>
                  </Th>
                  <Th isNumeric onClick={() => requestSort('liquidity')}>
                    <Box className="tvl_head">
                      TVL
                      <SortIcon
                        id={sortConfig.key}
                        direction={sortConfig.direction}
                        active={sortConfig.key === 'liquidity'} />
                    </Box>
                  </Th>
                  <Th isNumeric onClick={() => requestSort('volume1d')}>
                    <Box className="volume_head" >
                      24Hr Volume
                      <SortIcon
                        id={sortConfig.key}
                        direction={sortConfig.direction}
                        active={sortConfig.key === 'volume1d'} />
                    </Box>
                  </Th>
                  <Th isNumeric onClick={() => requestSort('volume1w')}>
                    <Box className="volume_head" >
                      7d Volume
                      <SortIcon
                        id={sortConfig.key}
                        direction={sortConfig.direction}
                        active={sortConfig.key === 'volume1w'} />
                    </Box>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {items?.length > 0 ? (
                  <>
                    {items.map((pair, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>
                            <Box className='poolcolm_data'>
                              <Image src="img/table_ic01.svg" alt="" />
                              {pair?.pair?.token0?.symbol}/{pair?.pair?.token1?.symbol}
                              <span>0.3%</span>
                            </Box>
                          </Td>
                          <Td isNumeric>{formatNumber(pair?.liquidity, true, false, 2)}</Td>
                          <Td isNumeric>
                            <Box className="volume_column" >
                              {formatNumber(pair?.volume1d, true, false, 2)}
                            </Box>
                          </Td>
                          <Td isNumeric>{formatNumber(pair?.volume1w, true, false, 2)}</Td>
                        </Tr>
                      )
                    })}
                  </>
                ) : null}
                {/* <Tr>
                  <Td>1</Td>
                  <Td><Box className='poolcolm_data'><Image src="img/table_ic01.svg" alt="" />UNI/ETH<span>0.3%</span></Box></Td>
                  <Td isNumeric>$361.06m</Td>
                  <Td isNumeric><Box className="volume_column" >$361.06m</Box></Td>
                  <Td isNumeric>$361.06m</Td>
                </Tr>
                <Tr>
                  <Td>2</Td>
                  <Td><Box className='poolcolm_data'><Image src="img/table_ic02.svg" alt="" />WBTC/ETH<span>0.3%</span></Box></Td>
                  <Td isNumeric>$144.06m</Td>
                  <Td isNumeric><Box className="volume_column" >$144.06m</Box></Td>
                  <Td isNumeric>$144.06m</Td>
                </Tr>
                <Tr>
                  <Td>3</Td>
                  <Td><Box className='poolcolm_data'><Image src="img/table_ic03.svg" alt="" />Tether USD (USDT)<span>0.5%</span></Box></Td>
                  <Td isNumeric>$15.06m</Td>
                  <Td isNumeric><Box className="volume_column" >$15.06m</Box></Td>
                  <Td isNumeric>$15.06m</Td>
                </Tr>
                <Tr>
                  <Th>4</Th>
                  <Th><Box className='poolcolm_data'><Image src="img/table_ic01.svg" alt="" />UNI/ETH<span>0.3%</span></Box></Th>
                  <Th isNumeric>$361.06m</Th>
                  <Th isNumeric><Box className="volume_column" >$361.06m</Box></Th>
                  <Th isNumeric>$361.06m</Th>
                </Tr>
                <Tr>
                  <Td>5</Td>
                  <Td><Box className='poolcolm_data'><Image src="img/table_ic02.svg" alt="" />WBTC/ETH<span>0.3%</span></Box></Td>
                  <Td isNumeric>$361.06m</Td>
                  <Td isNumeric><Box className="volume_column" >$144.06m</Box></Td>
                  <Td isNumeric>$361.06m</Td>
                </Tr>
                <Tr>
                  <Td>6</Td>
                  <Td><Box className='poolcolm_data'><Image src="img/table_ic03.svg" alt="" />Tether USD (USDT)<span>0.5%</span></Box></Td>
                  <Td isNumeric>$15.06m</Td>
                  <Td isNumeric><Box className="volume_column" >$15.06m</Box></Td>
                  <Td isNumeric>$15.06m</Td>
                </Tr>
                <Tr>
                  <Td>7</Td>
                  <Td><Box className='poolcolm_data'><Image src="img/table_ic01.svg" alt="" />UNI/ETH<span>0.3%</span></Box></Td>
                  <Td isNumeric>$361.06m</Td>
                  <Td isNumeric><Box className="volume_column" >$361.06m</Box></Td>
                  <Td isNumeric>$361.06m</Td>
                </Tr>
                <Tr>
                  <Th className='new_th'>8</Th>
                  <Th><Box className='poolcolm_data poolcolm_data_new'><Image src="img/table_ic02.svg" alt="" />WBTC/ETH<span>0.3%</span></Box></Th>
                  <Th isNumeric>$16.06m</Th>
                  <Th isNumeric><Box className="volume_column new_th" >$144.06m</Box></Th>
                  <Th isNumeric className='new_th'>$16.06m</Th>
                </Tr>
                <Tr>
                  <Td>9</Td>
                  <Td><Box className='poolcolm_data'><Image src="img/table_ic03.svg" alt="" />Tether USD (USDT)<span>0.5%</span></Box></Td>
                  <Td isNumeric>$481.06k</Td>
                  <Td isNumeric><Box className="volume_column" >$15.06m</Box></Td>
                  <Td isNumeric>$481.06k</Td>
                </Tr>
                <Tr>
                  <Th>10</Th>
                  <Th><Box className='poolcolm_data'><Image src="img/table_ic01.svg" alt="" />UNI/ETH<span>0.3%</span></Box></Th>
                  <Th isNumeric>$54.06m</Th>
                  <Th isNumeric><Box className="volume_column" >$361.06m</Box></Th>
                  <Th isNumeric>$54.06m</Th>
                </Tr>
                <Tr>
                  <Td className='new_th'>11</Td>
                  <Td><Box className='poolcolm_data poolcolm_data_new'><Image src="img/table_ic02.svg" alt="" />WBTC/ETH<span>0.3%</span></Box></Td>
                  <Td isNumeric></Td>
                  <Td isNumeric><Box className="volume_column new_th" >$144.06m</Box></Td>
                  <Td isNumeric className='new_th'>$16.06m</Td>
                </Tr>
                <Tr>
                  <Td>12</Td>
                  <Td><Box className='poolcolm_data'><Image src="img/table_ic01.svg" alt="" />UNI/ETH<span>0.3%</span></Box></Td>
                  <Td isNumeric></Td>
                  <Td isNumeric><Box className="volume_column" >$361.06m</Box></Td>
                  <Td isNumeric>$54.06m</Td>
                </Tr> */}
              </Tbody>
            </Table>
          </Box>
          <Box className='pagination_row'>
            <Button><Image src='img/pagination_prev.svg' alt="" /></Button>
            <Text>Page <span>1 of 1</span></Text>
            <Button><Image src='img/pagination_next.svg' alt="" /></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}