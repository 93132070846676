import { Contract } from '@ethersproject/contracts'
import { parseUnits } from '@ethersproject/units'
import BigNumber from 'bignumber.js'
import routerAbi from '../constants/abis/router.json'
import { Web3Provider } from '@ethersproject/providers'
import { BASE_ADDRESS, ROUTER_ADDRESS } from '../constants'
import { getDisplayBalance } from '../utils/formatBalance'

export class RouterService {
    private contract: Contract;
    private account: string;

    constructor(library: Web3Provider, account: string) {
        this.account = account;
        const signer = library.getSigner(account).connectUnchecked()
        this.contract = new Contract(ROUTER_ADDRESS, routerAbi, signer);
    }

    public async getOutputAmount(input: string, inputAddress: string, outputAddress: string, intputDecimals: number, outputDecimals: number) {
        const outputs: any = await this.contract.getAmountsOut(parseUnits(input, intputDecimals), [inputAddress, outputAddress]);
        return getDisplayBalance(new BigNumber(outputs[1].toString()), 6, outputDecimals);
    }

    public async getInputAmount(output: string, inputAddress: string, outputAddress: string, intputDecimals: number, outputDecimals: number) {
        const outputs: any = await this.contract.getAmountsIn(parseUnits(output, outputDecimals), [inputAddress, outputAddress]);
        return getDisplayBalance(new BigNumber(outputs[0].toString()), 6, intputDecimals);
    }

    public async swap(input: string, minOutputAmount: string, inputAddress: string, intputDecimals: number, outputDecimals: number) {
        if (inputAddress === BASE_ADDRESS) {
            return await this.contract.buyRooted(parseUnits(input, intputDecimals), parseUnits(minOutputAmount, outputDecimals), this.account);
        }
        console.log(parseUnits(input, intputDecimals).toString());

        console.log(parseUnits(minOutputAmount, outputDecimals).toString());
        return await this.contract.sellRooted(parseUnits(input, intputDecimals), parseUnits(minOutputAmount, outputDecimals), this.account);
    }
}